<template>
  <v-app>
    <vizion-app-bar />
  </v-app>
</template>

<script>
  export default {
    name: 'HomeLayout',

    components: {
      VizionAppBar: () => import('@/layouts/vizion/AppBar'),
    },
  }
</script>
